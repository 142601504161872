import React from 'react';
import Message from './styles';

export default ({ value }: { value: string }) => (
  <Message>
    <h2 className="h6" data-test="Message">
      {value}
    </h2>
  </Message>
);
